import React from 'react';
import { Form, Field } from 'react-final-form';
import {
  FieldTextInput,
  IconSearch,
  LocationAutocompleteInput,
  SecondaryButton,
} from '../../components';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import css from './SearchPage.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { isOriginInUse } from '../../util/search';
import { useConfiguration } from '../../context/configurationContext';
import { createResourceLocatorString } from '../../util/routes';
import { locationBounds } from '../../components/LocationAutocompleteInput/GeocoderMapbox';
import { getAddressFromSearch } from '../../util/genericHelpers';

const LocationAndRadiusFilter = props => {
  const { viewport } = props;
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const intl = useIntl();
  const address = getAddressFromSearch(history);

  const onSubmit = (values, form) => {
    const { profileAddress, addressRadius } = values;
    const { search, selectedPlace = {} } = profileAddress || {};
    const { origin, bounds } = selectedPlace;
    const originMaybe = isOriginInUse(config) ? { origin } : {};

    const distanceInMeters = addressRadius * 1000;
    const newBounds = addressRadius && locationBounds(origin, distanceInMeters);
    const searchParams = {
      ...originMaybe,
      address: search,
      bounds: addressRadius ? newBounds : bounds,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };
  const isMobileLayout = viewport?.width && viewport?.width > 0 && viewport?.width < 768;
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        profileAddress: address ? { search: address } : undefined,
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={css.locationFlow}>
          <div className={css.profileAddress}>
            <Field
              name="profileAddress"
              render={({ input, meta }) => (
                <>
                  <LocationAutocompleteInput
                    placeholder={intl.formatMessage({
                      id: 'LocationAndRadiusFilter.profileAddressLocationPlaceholder',
                    })}
                    iconClassName={css.searchInputIcon}
                    inputClassName={css.searchInput}
                    className={css.formFld}
                    predictionsClassName={css.searchPredictions}
                    input={{
                      ...input,
                      onChange: value => form.change('profileAddress', value),
                    }}
                    meta={meta}
                    shouldHideSearchIcon={true}
                  />
                  {meta.touched && meta.error && <span className={css.error}>{meta.error}</span>}
                </>
              )}
            />
          </div>
          <FieldTextInput
            disabled={!values?.profileAddress}
            className={css.profileAddress}
            type="number"
            id="addressRadius"
            name="addressRadius"
            placeholder={intl.formatMessage({
              id: 'LocationAndRadiusFilter.addressRadiusPlaceholder',
            })}
          />
          <SecondaryButton
            type="submit"
            className={css.submitButton}
            disabled={submitting || pristine}
          >
            {isMobileLayout ? (
              <IconSearch />
            ) : (
              <>{intl.formatMessage({ id: 'LocationAndRadiusFilter.submitButton' })}</>
            )}
          </SecondaryButton>
        </form>
      )}
    />
  );
};

export default LocationAndRadiusFilter;
